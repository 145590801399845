var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.lang.label)+" Section")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":_vm.lang.value + '.page_name',"rules":"","name":"The Page Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","label":"Page Name","error":failed ? errors[0]: null,"name":"page_name","fou":""},model:{value:(_vm.formData.page_name),callback:function ($$v) {_vm.$set(_vm.formData, "page_name", $$v)},expression:"formData.page_name"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":_vm.lang.value + '.page_heading',"rules":"","name":"The Page Heading"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","label":"Page Heading","error":failed ? errors[0]: null,"name":"page_heading","fou":""},model:{value:(_vm.formData.page_heading),callback:function ($$v) {_vm.$set(_vm.formData, "page_heading", $$v)},expression:"formData.page_heading"}})]}}])}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Content")]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},model:{value:(_vm.formData.page_content),callback:function ($$v) {_vm.$set(_vm.formData, "page_content", $$v)},expression:"formData.page_content"}})],1),_c('fg-input',{attrs:{"type":"text","label":"Meta Title","name":"meta_title"},model:{value:(_vm.formData.meta_title),callback:function ($$v) {_vm.$set(_vm.formData, "meta_title", $$v)},expression:"formData.meta_title"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('fg-text',{attrs:{"type":"text","label":"Meta Description","name":"meta_description"},model:{value:(_vm.formData.meta_description),callback:function ($$v) {_vm.$set(_vm.formData, "meta_description", $$v)},expression:"formData.meta_description"}})],1),_c('div',{staticClass:"col-md-6"},[_c('fg-text',{attrs:{"type":"text","label":"Meta Keywords","name":"meta_keywords"},model:{value:(_vm.formData.meta_keywords),callback:function ($$v) {_vm.$set(_vm.formData, "meta_keywords", $$v)},expression:"formData.meta_keywords"}})],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }